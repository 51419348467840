
export function androidAppMethod(action, param) {
    try {
        switch (action) {
            case 'getSessionId':
                return window.android.getSessionId();
            default:
                return window.android.perFormAppAction(JSON.stringify({ action, param })) || false;
        }
    } catch (e) {
            console
            .error(
                '调用android app注入接口报错:\n',
                `指令：[${action}]\n`,
                '参数：', param, '\n',
                '错误：', e
            );
        return false;
    }
}
