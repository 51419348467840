import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/ducksGame'
    },
    // 女用户数据列表
    {
      path: '/wList',
      name: 'wList',
      component: () => import('../components/wList.vue')
    },
    // 女用户数据详情
    {
      path: '/detail',
      name: 'detail',
      component: () => import('../components/detail.vue')
    }, 
    // 亲密度数据展示
    {
      path: '/intimacy',
      name: 'intimacy',
      component: () => import('../view/intimacy.vue')
    },
    // 推荐页面
    {
      path: '/promotion',
      name: 'promotion',
      component: () => import('../view/promotion.vue')
    },
    // 下载提示页面
    {
      path: '/download',
      name: 'download',
      component: () => import('../view/download.vue')
    },
    // 抓鸭子游戏
    {
      path: '/ducksGame',
      name: 'ducksGame',
      component: () => import('../view/ducksGame.vue')
    }, 
  ]
})