import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/rem.js'

import Vant from 'vant';
import 'vant/lib/index.css';
import VConsole from 'vconsole';
import {appMethod} from "@/app/AppMethod";

// if (process.env.NODE_ENV === 'development') {
  new VConsole();
// }

Vue.use(Vant);

Vue.config.productionTip = false

const sid = await appMethod.getSessionId();
if (sid) {
  Vue.prototype.$appSession = sid;
}else {
  // Vue.prototype.$appSession = 'fdf18e19e35a479d8209c6d45741e4a2';
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
