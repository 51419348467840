
import { androidAppMethod } from '@/app/androidInterface';


async function callAppMethod(action, params) {
    if (typeof window['android'] !== 'undefined') {
        return androidAppMethod(action, params);
    }
   return false;
}

class AppMethod {
    async getSessionId() {
        return await callAppMethod('getSessionId');
    }

}
export const appMethod = new AppMethod();
